// Third-parties
import moment from 'moment-timezone';

export default function exportExcel(dataSet, title, fraccionamiento, usuario, dateFormat, resTable = false, generateExcel, { excelColumnsShow, excelColumnsName, excelColumnsType }) {

    if(!dataSet) return { error: "Sin datos para exportar" };

    if (!Array.isArray(dataSet)) return { error: "Datos en formato incorrecto" };

    if (dataSet.length <= 0) return { error: "Sin datos para exportar" };

    if (!dataSet[0]) return { error: "No se han establecido las configuraciones para reporte." };

    if (!excelColumnsShow && !excelColumnsName) 
        return { error: "No se han establecido las configuraciones para reporte." };

    // Definición de columnas

    const columnParameters = excelColumnsShow.split(",");
    const columnNames = excelColumnsName.split(",");
    const columnTypes = excelColumnsType.split(",");
    // Construir las filas
    let rows = [];
    dataSet.forEach((element) => {
        const object = {};
        columnParameters.forEach((columnShow, index) => {
            const column = columnNames[index];
            const row = element[columnShow];
            object[column] = row;
        });

        if(element.tipo === "Restaurantes") object["id_restaurante"] = element.id_restaurante;
        rows.push(object);
    });

    //Datos a enviar al API
    const data = {
        columns: columnNames,
        rows,
        excelColumnsType: columnTypes,
        titulo: title,
        fraccionamiento: fraccionamiento || "Appmosphera",
        usuario: usuario || "",
        fecha: moment().format("DD/MM/YYYY HH:mm"),
        dateFormat,
        tz: moment.tz.guess(),
        restataurantes_table : resTable ? true : undefined
    };

    try {
        const excelData = generateExcel(data);

        if (!excelData || excelData.error) return { error: "No se pudo crear el documento de excel." };

        const bytes = new Uint8Array(excelData.buffer);
        const blob = new Blob([bytes]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        const fileName = `${title}_${moment().format("YYMMDDHHmm")}.xlsx`;
        link.download = fileName;
        link.click();

        return { created: "Generando archivo." };

    } catch (error) {
        return { error }
    }

}