import React, { Component, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";

// Conexión con el Context API
import { GlobalContext, Consumer } from "../context";

// Páginas Web
import Login from '../pages/login';
import CambiarPassword from '../pages/Password/actualizar';

import DashBoard from '../pages/dashboard';
import Owners from '../pages/owners';
import Incidents from '../pages/incidents';
import Guests from '../pages/guests';
import Houses from '../pages/houses';
import Invitation from '../pages/invitation';
import Catalogos from '../pages/catalogos';
import SubFraccPage from '../pages/subfraccionamiento'
import Complete from '../pages/complete';
import UserPage from '../pages/users'
import AdministracionPage from '../pages/administracionPage'
import Emergency from '../pages/emergengency'
import NotificacionDefinition from '../pages/notificacionDefinition'
import NotificacionHistory from '../pages/notificationHistory';
// Áreas Comúnes
import CommonAreas from '../pages/commonAreasPage';
import CommonAreasReservation from '../pages/commonAreasReservation2';
import CommonAreasHistorry from '../pages/commonPageHistory';
import CommonAreasPenalizaciones from '../pages/commonAreasPenalizaciones';

import incidents from "../pages/incidents";
import IncidentsHistory from "../pages/incidentsHistoryPage"
import SecurityBitacora from "../pages/securitybitacora"
import Paqueteria from "../pages/paqueteria/Paqueteria";
import Reports from "../pages/reports"
import BudgerConcepts from '../pages/budgetConcept';
import BudgetTeoric from '../pages/budgetTeoric';
import budgetExpenses from "../pages/budgetExpenses";
import Profiles from '../pages/profiles';
import Environment from '../pages/environment';
import accountStatement from '../pages/docCharge/paymentDocuments'
import receipmentPayment from '../pages/docCharge/receipmentPayment'
import invoiceDocuments from '../pages/docCharge/invoiceDocuments'
//import accountStatement from '../pages/accountStatement';
import InquilinoPage from '../pages/inquilinoPage'
import NotificationTipe from '../pages/configurationType'
import ConfigurationTypeReports from '../pages/configurationTypeReports'
import FamiliaresHistory from '../pages/familiaresHistory';
import personalDomesticoHistory from '../pages/personalDomesticoHistory';
import vacacionalHistory from '../pages/vacacionalHistoty';
import proveedoresHistory from '../pages/Frecuentes/proveedoresHistory';
import Contactos from '../pages/contactos';
import toaster from "toasted-notes";
import Error from '../components/errors/error';
import Documents from '../pages/documents';
import BillingSenderData from "../pages/Billing/Concepts/senderData";
import ReceiverData from "../pages/Billing/Concepts/receiverData";
import ParametersData from "../pages/Billing/Concepts/parametersData";
import Parameters from '../pages/Billing/Params/parametersPage';
import Products from '../pages/Billing/Products/products';
import Rules from '../pages/Billing/Rules/rules'
import DasboardDocs from '../pages/docCharge/dashboardDoc';
import Personas from '../pages/personas';
import Pruebas from '../pages/Pruebas/index';
import TarjetaVirtual from '../pages/TarjetaVirtual/TarjetaVirtualPage';
//Personal Seguridad
import PersonalSeguridad from '../pages/personal/personalSeguridad';
import PersonalDepartamentos from '../pages/personal/departamentos';
import PersonalJornadas from '../pages/personal/jornadas';
import PersonalPosiciones from '../pages/personal/posiciones';
import PersonalTurnos from '../pages/personal/turnos';
//Restaurantes
import Res_Config from '../pages/restaurantes/configuracion';
import Res_Platos from '../pages/restaurantes/platos';
import Res_Pedidos from '../pages/restaurantes/pedidos';
//Invitaciones
import Inv_Activas from '../pages/invitaciones/activas';
import Inv_Historial from '../pages/invitaciones/historial';
// Encuestas
import Encuestas from '../pages/Encuestas/encuestas';
// Galeria
import Galeria from '../pages/Galerias';

import "toasted-notes/src/styles.css";
import { Howl } from 'howler';
/**Socket */
import Socket from './socket';

import Request from './httpClient';
import ProveedorPage from "../pages/proveedorPage";
import FacturaPage from "../pages/facturaPage";

const req = new Request();

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={
            props => {
                const usuario = window.localStorage.getItem("iGateAdmin");
                if (usuario) {
                    return <Component {...props} />;
                } else {
                    let path = window.location.pathname;
                    if (path.includes(".")) {
                        window.location = "https://documentos.appmosphera.com/" + path;
                    }

                    return (
                        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                    );
                }
            }
        }
    />
);

const VigenciaPassword = Consumer(({ context }) => {
    let history = useHistory();
    const { location } = history;

    async function validarVencimiento(usuario) {
        const res = await req.post('/users/password/vencimiento', usuario);
        if (!res.error) {
            if (res.vencida) {
                const modal = document.getElementById('expiry-change-password');

                if (modal) {
                    context.setExpiratePassword(true);
                    modal.classList.remove('hide-dinamic-modal');
                    modal.classList.add('show-dinamic-modal');
                }
            }
        }
    }

    useEffect(() => {
        if (!(location.pathname === '/login' || location.pathname === '/' || location.pathname === '/contraseña/actualizar')) {
            const usuario = window.localStorage.getItem("iGateAdmin");
            if (usuario) {
                validarVencimiento(JSON.parse(usuario));
            }
        }
    }, [location.pathname]);

    return <CambiarPassword />
});

const Routes = (props) => {
    const [beepSond, setbeepSond] = useState('https://documentos.appmosphera.com/sounds/beep.mp3');
    const [sound, setSound] = useState({
        sound: new Howl({
            src: 'https://documentos.appmosphera.com/sounds/beep.mp3',
            html5: true,
            // loop:       true,
        })
    });

    useEffect(() => {
        Socket.connect();

        Socket.emit('action', { type: 'listAdmin', data: { idfrac: localStorage.getItem('frac') } });

        Socket.on('newEmergency', (data) => {
            sound.play();
            toaster.notify(`El residente ${data.name || ''}, tiene una emergencia`, {
                duration: 10000
            });
            setTimeout(() => { sound.stop(); }, 9500);
        });

        Socket.on('newPedidoRestaurante', (data) => {
            toaster.notify(`Se ha realizado un nuevo pedido en restaurantes.`, {
                duration: 5000
            });
        });

        Socket.on('newReservaAreaComun', (data) => {
            toaster.notify(`Se ha realizado una reserva de área común.`, {
                duration: 5000
            });
        });
        Socket.on('deleteFrecuentes', (data) => {
            toaster.notify(`Se ha cancelado una invitación frecuente`, {
                duration: 5000
            });
        });

    }, []);

    function renderRoutes() {
        let routes = [
            { path: '/catalogos/viviendas', component: Houses, private: true },
            { path: '/catalogos/propietarios', component: Owners, private: true },
            { path: '/catalogos/inquilinos', component: InquilinoPage, private: true },
            { path: '/catalogos/contactos', component: Contactos, private: true },
            { path: '/catalogos/subfraccionamientos', component: SubFraccPage, private: true },
            { path: '/catalogos/presupuestos/concepto', component: BudgerConcepts, private: true },
            { path: '/catalogos/presupuestos/teorico', component: BudgetTeoric, private: true },
            { path: '/catalogos/presupuestos/gastos', component: budgetExpenses, private: true },
            { path: '/catalogos/presupuestos/proveedores', component: ProveedorPage, private: true },
            { path: '/catalogos/presupuestos/facturas', component: FacturaPage, private: true },
            { path: '/catalogos/estados/cuentas', component: accountStatement, private: true },
            { path: '/catalogos/facturacion', component: invoiceDocuments, private: true },
            { path: '/catalogos/estados/pagos', component: receipmentPayment, private: true },
            { path: '/estados/dashboard', component: DasboardDocs, private: true },
            { path: '/users', component: UserPage, private: true },
            { path: '/administracion/emergencias', component: Emergency, private: true },
            { path: '/administracion/notificaciones/definicion', component: NotificacionDefinition, private: true },
            { path: '/administracion/notificaciones/historial', component: NotificacionHistory, private: true },
            { path: '/administracion/areas/instalaciones', component: CommonAreas, private: true },
            { path: '/administracion/areas/reservacion', component: CommonAreasReservation, private: true },
            { path: '/administracion/areas/historial', component: CommonAreasHistorry, private: true },
            { path: '/administracion/areas/penalizaciones', component: CommonAreasPenalizaciones, pirvate: true },
            { path: '/administracion/quejas/pendientes', component: incidents, private: true },
            { path: '/administracion/quejas/historial', component: IncidentsHistory, private: true },
            { path: '/administracion/quejas/tipos', component: ConfigurationTypeReports, private: true },
            { path: '/administracion/invitados/familiar', component: FamiliaresHistory, private: true },
            { path: '/administracion/invitados/personalDom', component: personalDomesticoHistory, private: true },
            { path: '/administracion/invitados/vacacional', component: vacacionalHistory, private: true },
            { path: '/administracion/invitados/proveedores', component: proveedoresHistory, private: true },
            { path: '/administracion/tarjetaVirtual/historial', component: TarjetaVirtual, private: true },
            { path: '/perfiles', component: Profiles, private: true },
            { path: '/entornos', component: Environment, private: true },
            { path: '/notificaciones/tipos', component: NotificationTipe, private: true },
            { path: '/administracion/bitacora/historial', component: SecurityBitacora, private: true },
            { path: '/subfraccionamientos/documentos', component: Documents, private: true },
            { path: '/facturacion/emisor', component: BillingSenderData, Private: true },
            { path: '/facturacion/receptor', component: ReceiverData, private: true },
            { path: '/facturacion/parametros', component: Parameters, private: true },
            { path: '/facturacion/productos', component: Products, private: true },
            { path: '/facturacion/reglas', component: Rules, private: true },
            { path: '/catalogos/personas', component: Personas, private: true },
            { path: '/restaurantes/configuracion', component: Res_Config, private: true },
            { path: '/restaurantes/platos', component: Res_Platos, private: true },
            { path: '/restaurantes/pedidos', component: Res_Pedidos, private: true },
            { path: '/encuestas/encuestas', component: Encuestas, private: true },
            { path: '/encuestas/asambleas', component: Encuestas, private: true },
            { path: '/administracion/invitaciones/activas', component: Inv_Activas, private: true },
            { path: '/administracion/invitaciones/historial', component: Inv_Historial, private: true },
            { path: '/galerias/galerias', component: Galeria, private: true },
            { path: '/pruebas', component: Pruebas, private: true },
            { path: '/personal/seguridad', component: PersonalSeguridad, private: true },
            { path: '/personal/departamentos', component: PersonalDepartamentos, private: true },
            { path: '/personal/jornadas', component: PersonalJornadas, private: true },
            { path: '/personal/posiciones', component: PersonalPosiciones, private: true },
            { path: '/personal/turnos', component: PersonalTurnos, private: true },
            { path: '/contraseña/actualizar/:expiro', component: CambiarPassword, private: false },
            { path: '/contraseña/actualizar', component: CambiarPassword, private: false },
            { path: '/administracion/paqueteria', component: Paqueteria, private: true }
        ];

        let routesRender = [];

        routesRender = routes.map((route, index) => {
            if (route.private)
                return <PrivateRoute key={index} exact path={route.path} component={route.component} />
            else
                return <Route key={index} exact path={route.path} component={route.component} />
        });

        routesRender.push(<Route exact path={'/'} component={Login} key={routes.length + 2} />);
        routesRender.push(<Route exact path={'/login'} component={Login} key={routes.length + 3} />);
        routesRender.push(<Route exact path="/complete/:id" component={Complete} key={routes.length + 4} />);
        routesRender.push(<Route exact path="/invitation/:id" component={Invitation} key={routes.length + 5} />);
        routesRender.push(<PrivateRoute exact path="/tablero" component={DashBoard} key={routes.length + 6} />);
        routesRender.push(<PrivateRoute exact path="/administracion" component={Emergency} key={routes.length + 7} />);
        routesRender.push(<PrivateRoute exact path="/catalogos/incidencias" component={Incidents} key={routes.length + 8} />);
        routesRender.push(<PrivateRoute exact path="/reportes" component={Reports} key={routes.length + 9} />);
        routesRender.push(<PrivateRoute exact path="/invitados" component={Guests} key={routes.length + 10} />);
        routesRender.push(<PrivateRoute component={Error} key={routes.length + 11} />);

        return routesRender;
    }

    return (
        <GlobalContext>
            <BrowserRouter>
                <VigenciaPassword />
                <div className="flex main">
                    <div className="column full">
                        <Switch>
                            {renderRoutes()}
                        </Switch>
                    </div>
                </div>
            </BrowserRouter>
        </GlobalContext>
    );
}


export default Routes;