
// React and Third-party
import React, { useEffect, useState, useRef } from 'react';
import NumberFormat from 'react-number-format';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';

// Components
import TableHeadSort from './TableHeadSort';
import PaginadorParent from '../../../components/paginador/PaginadorParent';

// Hooks
import useExcel from '../../../hooks/useExcel';

// Helpers
import sortableData from '../../../helpers/sortableDataTable';
import exportExcel from '../../../helpers/exportExcel';

const excelConfig = {
    excelColumnsName: "Residente,Cantidad,Tipo,Detalle,Fecha",
    excelColumnsShow: "inquilino,cantidad,tipo,descripcion,fecha",
    excelColumnsType: "string,money,string,string,date" //Change to datetime
}

const dateRangeFormat = { desde: "", hasta: "" };

export default function MovimientosTable(props) {

    const { byVivienda, header, movimientos, loadingMovimientos, dateRange = dateRangeFormat, inquilino, fraccionamiento, usuario } = props;

    const { generateExcel } = useExcel();

    const [keySort, setKeySort] = useState({ name: "", direction: "" });

    const [loadingExcel, setLoadingExcel] = useState(false);

    const [prePagedData, setPrePagedData] = useState(movimientos);
    const [pagedData, setPagedData] = useState(prePagedData);
 
    useEffect(() => {
      
        setPrePagedData(movimientos);

    }, [movimientos]);

    const dateRangeFetched = useRef(moment(dateRange.hasta).format("DD/MM/YYYY") + " - " +
        moment(dateRange.desde).format("DD/MM/YYYY"));

    const colsNum = byVivienda ? 5 : 7;

    const sortByKey = (newOrder) => {

        const { name, direction } = newOrder;

        let sd = new sortableData();
        const { items } = sd.sortBy(name, prePagedData, direction);

        setKeySort(newOrder);
        setPrePagedData([...items]);
    };
    
    function exportExcelHandler()  {

        setLoadingExcel(true);

        const { error, created } = exportExcel(
            movimientos, 
            "Historial de " + inquilino,
            fraccionamiento || "Appmosphera",
            usuario || "",
            "dd/mm/yyyy HH:mm:ss",
            true,
            generateExcel,
            { ...excelConfig }
        )

        if(error) cogoToast.error(error, {
            position: "bottom-right",
        });

        if(created) cogoToast.success("Excel generado", {
            position: "bottom-right",
        })

        setLoadingExcel(false);
    }

    const LoadingContent = () => (
        <tr>
            <td className="text-center" colSpan={colsNum} style={{ height: 200 }}>
                <i className="fas fa-spinner fa-spin"></i>
            </td>
        </tr>
    )

    const EmptyContent = () => (
        <tr>
            <td className="text-center" colSpan={colsNum} style={{ height: 200 }}>
                <h4>Sin Movimientos</h4>
            </td>
        </tr>
    )

    const TableContent = () => pagedData.map((mov, index) => (
        <tr key={mov.id_tarjeta_detalle}>
            <td className='text-left'>
                {mov.inquilino}
            </td>
            {!byVivienda &&
                <>
                    <td className='text-left'>
                        {mov.Subfraccionamiento}
                    </td>
                    <td className='text-left'>
                        {mov.vivienda}
                    </td>
                </>
            }
            <td className="text-center">
                <NumberFormat
                    value={mov.cantidad}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                />
            </td>
            <td className='text-left'>{mov.tipo}</td>
            <td className='text-left'>{mov.descripcion}</td>
            <td className='text-right'>{moment(mov.fecha).format("DD/MM/YY hh:mm")}</td>
        </tr>
    ))

    return (
        <div className="card-table card-owners column" id="card-owners">
            <table className='full table-equal'>
                <ColConfig byVivienda={byVivienda} />
                <thead /*className='sticky-th'*/ style={{ position: "sticky", top: 226/*293*/ + (header ? 37.5 : 0), zIndex: 1 }} >
                    <tr style={{ display: header ?  "auto" : "none" }}>
                        <th colSpan={5} style={{ paddingLeft: 2, borderBottomWidth: 0 }}>
                            <div className="row full align-center" style={{ justifyContent: 'space-between', paddingBlock: 8 }}>
                                <h4 style={{ marginRight: 8 }}>
                                    Historial de Movimientos
                                </h4>
                                <div className='row' style={{ alignItems: "center", paddingRight: 8 }}>
                                    <p style={{ fontWeight: "normal", marginRight: 8 }}>
                                        <b>Rango:</b> {dateRangeFetched.current}
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-small color-white"
                                        style={{ position: "relative" }}
                                        onClick={() => exportExcelHandler()}
                                    >
                                        {loadingExcel &&
                                            <div style={{ position: "absolute", left: 0, width: "100%" }}>
                                                <i className="fas fa-spinner fa-spin" />
                                            </div>}
                                        <b style={{ color: "#FFF", fontWeight: "100", fontSize: "0.8rem", opacity: !loadingExcel ? 1 : 0 }}>
                                            <i className="far fa-file-excel font-text" />
                                            &nbsp; Excel
                                        </b>
                                    </button>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th className="text-left">
                            <TableHeadSort
                                title="Residente"
                                orderName="inquilino"
                                orderKey={keySort}
                                onOrderKeyChange={(value) => sortByKey(value)}
                            />
                        </th>
                        {!byVivienda &&
                            <>
                                <th className="text-left">
                                    <TableHeadSort
                                        title="Subfraccionamiento"
                                        orderName="Subfraccionamiento"
                                        orderKey={keySort}
                                        onOrderKeyChange={(value) => sortByKey(value)}
                                    />
                                </th>
                                <th className="text-left">
                                    <TableHeadSort
                                        title="Vivienda"
                                        orderName="vivienda"
                                        orderKey={keySort}
                                        onOrderKeyChange={(value) => sortByKey(value)}
                                    />
                                </th>
                            </>
                        }
                        <th>
                            <TableHeadSort
                                className="justify-start"
                                title="Cantidad"
                                orderName="cantidad"
                                orderKey={keySort}
                                onOrderKeyChange={(value) => sortByKey(value)}
                            />
                        </th>
                        <th className='text-left'>Servicio</th>
                        <th className='text-left'>Detalle</th>
                        <th>
                            <TableHeadSort
                                className="justify-end"
                                title="Fecha"
                                orderName="fecha"
                                orderKey={keySort}
                                onOrderKeyChange={(value) => sortByKey(value)}
                            />
                        </th>
                    </tr>
                </thead>
                <tbody /*className='extra-padding'*/>
                    {loadingMovimientos ?
                        <LoadingContent />
                        :
                        pagedData.length === 0 ?
                        <EmptyContent />
                        :
                        <TableContent />
                    }
                </tbody>
            </table>
            {!loadingMovimientos &&
                <PaginadorParent
                    data={prePagedData}
                    onPagedDataChange={({ pagedData }) => setPagedData(pagedData)}
                />
            }
        </div>
    )
}

const ColConfig = ({ byVivienda }) => {

    const widths = !byVivienda ?
        ["15%", "17%", "15%", "10%", "10%", "15%", "13%"]
        : ["20%", "20%", "20%", "20%", "20%"];

    return <colgroup>
        {widths.map((width, index) => <col key={index} width={width}></col>)}
    </colgroup>
    
}