import moment from "moment-timezone";
import XLSX from "xlsx";

const useExcel = () => {
  const isFloat = (n) => Number(n) === n && n % 1 !== 0;
  const isInteger = (n) => Number(n) === n && n % 1 === 0;

  const generateExcel = (data) => {
    try {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet([], {dense: true});

      const createdAtString = data.fecha;
      const createdAt = moment(createdAtString, "DD/MM/YYYY HH:mm").toDate();
      XLSX.utils.sheet_add_aoa(ws, [[data.fraccionamiento]], {origin: "A1"});
      XLSX.utils.sheet_add_aoa(ws, [[data.titulo]], {origin: "A2"});
      XLSX.utils.sheet_add_aoa(ws, [[createdAt]], {origin: "A3", dateNF: "dd/mm/yyyy hh:mm"});
      //ws["!data"][2][0].z = "dd/mm/yyyy hh:mm";

      const startRow = 4;

      data.columns.forEach((column, columnIndex) => {
        const cellAddress = XLSX.utils.encode_cell({
          r: startRow,
          c: columnIndex,
        });
        XLSX.utils.sheet_add_aoa(ws, [[column]], {origin: cellAddress});
        ws["!cols"] = ws["!cols"] || [];
        ws["!cols"][columnIndex] = { wch: 20 };
      });

      data.rows.forEach((row, rowIndex) => {
        let dateTimeCols = [];
        const rowData = data.columns.map((column, columnIndex) => {
          const dataType =
            data.excelColumnsType && data.excelColumnsType[columnIndex];

          if (dataType === "number" || dataType === "money") {
            const cellValue =
              isFloat(row[column]) || isInteger(row[column])
                ? Number(row[column])
                : 0;
            const cellFormat = dataType === "money" ? "$#,##0.00" : "General";
            return {v: cellValue, t: "n", z: cellFormat};
          } else if (dataType === "date") {
            return moment(row[column]).isValid()
              ? moment(row[column]).toDate()
              : "";
          } else if (dataType === "datetime") {
            dateTimeCols.push(columnIndex);
            if (moment(row[column]).isValid()) {
              return new Date(row[column]);
            } else {
              return "";
            }
          } else if (dataType === "image") {
            return "No disponible";
          } else {
            return row[column];
          }
        });

        const rowIndexWithOffset = startRow + rowIndex + 1;
        XLSX.utils.sheet_add_aoa(ws, [rowData], {
          origin: {r: rowIndexWithOffset, c: 0},
        });
        dateTimeCols.forEach((col) => {
          ws["!data"][rowIndexWithOffset][col].z = "dd/mm/yyyy hh:mm";
        });
      });

      if (data.restataurantes_table) {

        const restaurantes = data.rows.reduce((prev, current) => {

          if (current.Tipo === "Restaurantes") {

            if (Object.keys(prev).find(key => key === current.id_restaurante)) {
              return prev;
            }

            prev[current.id_restaurante] = { nombre: current.Detalle, cell: { t: "n", z: "$#,##0.00", f: "" } };
          }

          return prev;

        }, {});

        if (Object.entries(restaurantes).length > 0) {

          const startColumn = data.columns.length + 1;
          const catalogoColumnIndex = data.columns.findIndex(column => column === "Tipo");
          const cantidadColumnIndex = data.columns.findIndex(column => column === "Cantidad");

          data.rows.forEach((row, rowIndex) => {
            data.columns.forEach((column, columnIndex) => {
              if (columnIndex === catalogoColumnIndex && row[column] === "Restaurantes") {
                const key = row.id_restaurante;
                restaurantes[key].cell.f += XLSX.utils.encode_cell({ r: startRow + 1 + rowIndex, c: cantidadColumnIndex }) + "+";//String.fromCharCode(initColumnKey) + (startRow + 2 + rowIndex) + "+";
              }
            })
          })

          const startTableCell = XLSX.utils.encode_cell(({ r: startRow, c: startColumn }));
          const startSumCell = XLSX.utils.encode_cell(({ r: startRow + 1, c: startColumn + 1 }));
          const endSumCell = XLSX.utils.encode_cell(({ r: startRow + 1 + Object.keys(restaurantes).length - 1, c: startColumn + 1 }));

          const totalSumRange = `SUM(${startSumCell}:${endSumCell})`;

          Object.values(restaurantes).forEach((restaurante) => {
            if (restaurante.cell) {
              const formulaString = restaurante.cell.f;
              restaurante.cell.f = formulaString.slice(0, formulaString.length - 1);
            }
          });

          const restaurantesTable = [
            ["Restaurantes", "Cantidad"],
            ...Object.values(restaurantes).map(restaurante => [restaurante.nombre, restaurante.cell]),
            ["Total", { t: "n", z: "$#,##0.00", f: totalSumRange }]
          ]

          ws["!cols"][startColumn] = { wch: 20 };
          ws["!cols"][startColumn + 1] = { wch: 20 };

          XLSX.utils.sheet_add_aoa(ws, restaurantesTable, { origin: startTableCell });
        }
      }

      XLSX.utils.book_append_sheet(wb, ws, "Appmosphera");
      const excelData = XLSX.write(wb, {type: "array", bookType: "xlsx"});
      return {buffer: excelData};
    } catch (error) {
      console.log(error);
      return {error: true, message: error.message};
    }
  };

  return {
    generateExcel,
  };
};

export default useExcel;
